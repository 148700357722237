import React, { PureComponent } from 'react'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as S from '../ManageMembership.styles'
import SubscriptionSelect from './SubscriptionSelect'
import {
  BACKGROUND,
  DARK_BLUE,
  PRIMARY,
  SECONDARY,
} from '../../../../../constants/colors'
import { PRIVACY, TERMS } from '../../../../../constants/routes'
import { navigate } from 'gatsby'
import {
  SubscriptionResponse,
  UserMetaResponse,
} from '../../../../../redux/actions'
import { DeepReadonlyArray } from '../../../../../services'
import { getDisplayPrice } from '../../../../../services/currency-codes'

interface Props {
  userMeta: UserMetaResponse
  subscriptions: DeepReadonlyArray<SubscriptionResponse>
  isUpdating: boolean
  isCancelling: boolean
  updateMembership(priceId: string): void
  cancelMembership(): void
}

interface State {
  typeSelected: string
  showCancel: boolean
}

class UpdateMembership extends PureComponent<Props, State> {
  state = {
    typeSelected: this.props.userMeta.priceId,
    showCancel: false,
  }

  getEndOfBillingPeriod = (timestamp: number): string => {
    const date = moment.unix(timestamp).format('MMMM Do, YYYY')
    return date
  }

  handleSelect = (priceId: string) => {
    this.setState({
      typeSelected: priceId,
    })
  }

  handleUpdateClicked = () => {
    const { updateMembership } = this.props
    const { typeSelected } = this.state

    if (typeSelected !== '') {
      updateMembership(typeSelected)
    }
  }

  getBilledPrice = (): string => {
    const {
      userMeta: { priceId },
      subscriptions,
    } = this.props

    const subscription = subscriptions.find(
      (s: SubscriptionResponse) => s.priceId === priceId,
    )
    if (subscription) {
      return getDisplayPrice(subscription.amount, subscription.currency)
    }
    return ''
  }

  getMembershipStatus = (): string => {
    const {
      userMeta: { status },
    } = this.props

    if (status === 'trialing' || status === 'active') {
      return 'active'
    } else if (status === 'past_due') {
      return 'past due'
    } else {
      return status
    }
  }

  isMembershipActive = (): boolean => {
    const {
      userMeta: { status },
    } = this.props
    return status === 'trialing' || status === 'active' ? true : false
  }

  render() {
    const {
      subscriptions,
      userMeta,
      isUpdating,
      isCancelling,
      cancelMembership,
    } = this.props
    const { typeSelected, showCancel } = this.state

    return (
      <>
        <S.MembershipStatusWrap>
          <S.MembershipStatusText style={{ fontSize: 20, margin: 0 }}>
            Membership status:
          </S.MembershipStatusText>
          <S.MembershipStatus data-test={'membershipStatus'}>{this.getMembershipStatus()}</S.MembershipStatus>
        </S.MembershipStatusWrap>

        {this.isMembershipActive() ? (
          <>
            {subscriptions.map((s: SubscriptionResponse) => (
              <SubscriptionSelect
                key={s.priceId}
                subscription={s}
                onSelect={() => this.handleSelect(s.priceId)}
                selected={s.priceId === typeSelected}
              />
            ))}
            <S.Text>
              On {this.getEndOfBillingPeriod(userMeta.currentPeriodEnd)}, a
              recurring charge of {this.getBilledPrice()} /{' '}
              {userMeta.type === 'annual' ? 'year' : 'month'} will automatically
              apply.
            </S.Text>

            <S.ActionButton
              isActive={typeSelected !== userMeta.priceId}
              onClick={() => this.handleUpdateClicked()}
              data-test={'updateMembership'}
            >
              <S.ActionText>Update Membership</S.ActionText>
              {isUpdating && (
                <S.SpinnerWrap>
                  <CircularProgress size={18} color="secondary" />
                </S.SpinnerWrap>
              )}
            </S.ActionButton>
            <S.Text>
              By selecting ‘Update Membership’ you agree to our{' '}
              <span
                onClick={() => navigate(TERMS)}
                style={{ cursor: 'pointer', color: DARK_BLUE }}
              >
                Terms of Service
              </span>{' '}
              and{' '}
              <span
                onClick={() => navigate(PRIVACY)}
                style={{ cursor: 'pointer', color: DARK_BLUE }}
              >
                Privacy Policy
              </span>
              .
            </S.Text>

            <S.ActionButton
              isActive={true}
              style={{ marginTop: 48 }}
              onClick={() => this.setState({ showCancel: true })}
              data-test={'cancelMembership'}
            >
              <S.ActionText>Cancel Membership</S.ActionText>
              {isCancelling && (
                <S.SpinnerWrap>
                  <CircularProgress size={18} color="secondary" />
                </S.SpinnerWrap>
              )}
            </S.ActionButton>
            {showCancel && (
              <>
                <S.Text>
                  Are you sure you want to cancel your membership?
                </S.Text>
                <S.ButtonRow>
                  <S.HalfButton
                    color={BACKGROUND}
                    style={{ marginRight: 8, marginLeft: 4 }}
                    onClick={() => this.setState({ showCancel: false })}
                  >
                    <S.ButtonText color={PRIMARY}>NO</S.ButtonText>
                  </S.HalfButton>
                  <S.HalfButton
                    color={SECONDARY}
                    style={{ marginLeft: 8, marginRight: 4 }}
                    onClick={() => {
                      this.setState({ showCancel: false })
                      cancelMembership()
                    }}
                    data-test={'confirmCancelMembership'}
                  >
                    <S.ButtonText color={BACKGROUND}>YES</S.ButtonText>
                  </S.HalfButton>
                </S.ButtonRow>
              </>
            )}
          </>
        ) : (
          <S.MembershipStatusWrap>
            <S.MembershipStatusText style={{ textAlign: 'center' }}>
              Please update your credit card information to reactivate your
              account.
            </S.MembershipStatusText>
          </S.MembershipStatusWrap>
        )}
      </>
    )
  }
}

export default UpdateMembership
